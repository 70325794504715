import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Link } from 'react-router-dom';
import {
    Typography,
    Box,
    CardContent,
    CardMedia,
    Button,
} from '@mui/material';
const FeatureTile = (props) => {
    const { reverse, title, text, link, linkRef, image, id, link2, linkRef2 } = props;
    return (
        <Box sx={{ marginTop: 10, display: 'flex', flexDirection: { xs: 'column', md: reverse ? 'row-reverse' : 'row' }, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="h2" variant="h5" id={id} sx={{ scrollMargin: 80 }}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {text}
                    </Typography>
                </CardContent>
                {link &&
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Button to={linkRef} component={Link}>
                            {link}
                        </Button>
                        {link2 &&
                            <Button to={linkRef2} component={Link}>
                                {link2}
                            </Button>
                        }
                    </CardContent>
                }

            </Box>

            <CardMedia
                sx={{ maxWidth: { xs: 'auto', md: 460 }, maxHeight: { md: 350 }, justifyItems: { xs: 'center' }, padding: 5 }}
                component="img"
                image={image}
                alt="pedestrian gate"
            />

        </Box>
    );
}

export default FeatureTile;