import React from 'react';
import {
    Typography,
    Box,
} from '@mui/material';

const GeneratorStep = ({ description, children }) => {

    return (
        <Box sx={{ width: '100%', display: 'flex', pt: 2, minHeight: { md: 250 }, flexDirection: { md: 'row', xs: 'column' }, justifyContent: 'center' }}>
            <Box sx={{ maxWidth: { md: '50%' }, marginRight: { md: 5 }, alignItems: 'center', display: 'flex' }}>
                <Typography style={{ whiteSpace: 'pre-line' }} >
                    {description}
                </Typography>
            </Box>
            <Box sx={{
                '& .MuiTextField-root': { m: 1, width: '10ch' },
                display: 'flex', flexDirection: 'row', alignContent: 'center', flexWrap: 'wrap'
            }}>
                {children}

            </Box>
        </Box>
    );
}

export default GeneratorStep;