import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function GatechIcon(props) {
    return (
        <SvgIcon
            {...props}
        >

            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                //strokeWidth={1.5}
                stroke="currentColor"
            >
                <path
                    //d="M32.173 38.432 l12.827 -0.000058594 l0 0.5676 c0 11.58 -9.4206 21 -21 21 s-21 -9.42 -21 -21 s9.4206 -21 21 -21 c4.5856 0 8.9424 1.4513 12.6 4.1983 l-0.6819 0.90768 c-3.4586 -2.5976 -7.5798 -3.9707 -11.917 -3.9707 c-6.627 0 -12.503 3.2655 -16.114 8.268 c2.4821 3.1687 3.8471 7.1064 3.8471 11.144 c0 4.2245 -1.4881 8.331 -4.1884 11.57 c3.5753 5.2739 9.6162 8.7468 16.454 8.7468 c10.764 0 19.555 -8.6046 19.856 -19.297 l-11.684 0 l0 -1.1351 z M4.1351 39 c0 3.6866 1.0133 7.14 2.7709 10.103 c2.3823 -2.9896 3.6931 -6.7194 3.6931 -10.555 c0 -3.6598 -1.2075 -7.2258 -3.3898 -10.142 c-1.9437 3.0689 -3.0742 6.7014 -3.0742 10.595 z"
                    d="m16.087 11.716 6.413 0v.284c0 5.79-4.71 10.5-10.5 10.5s-10.5-4.71-10.5-10.5 4.71-10.5 10.5-10.5c2.293 0 4.471.726 6.3 2.099l-.341.454c-1.729-1.299-3.79-1.985-5.959-1.985-3.313 0-6.251 1.633-8.057 4.134 1.241 1.584 1.924 3.553 1.924 5.572 0 2.112-.744 4.165-2.094 5.785 1.788 2.637 4.808 4.373 8.227 4.373 5.382 0 9.778-4.302 9.928-9.649h-5.842v-.568zm-14.019.284c0 1.843.507 3.57 1.385 5.052 1.191-1.495 1.847-3.36 1.847-5.277 0-1.83-.604-3.613-1.695-5.071-.972 1.534-1.537 3.351-1.537 5.298z"
                >

                </path>
            </svg>
        </SvgIcon>
    );
}