import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';
import ChatActions from '../../redux/ChatRedux'
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import {
    Typography,
    Box,
    TextField,
    Card,
    Paper,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { SentMessage, ReceivedMessage } from './Bubbles';

const PopupChat = props => {
    const { messages } = props
    const [textField, setTextField] = useState("");

    //const [chatopen, setChatopen] = useState(false)
    //const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const { lang } = useParams();
    const bottomRef = useRef();
    const lastRef = useRef();

    const toggle = e => {
        props.setOpen(!props.isOpen)
    }



    const scrollToBottom = () => {
        try {
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } catch (ex) {

        }
    };

    function customNavigate(link) {
        navigate("/" + lang + link)
    }

    const handleSend = async e => {

        if (props.isLoading || textField.trim() === "") {
            setTextField("");
            return;
        }
        props.setMessage({ role: 1, content: textField })

        setTextField("");

        props.sendMessage(textField, props.messages, customNavigate)

    }

    useEffect(() => {
        if (props.messages) {
            scrollToBottom();
        }
    }, [props.messages, props.isOpen, lastRef.current?.lastElementChild]);

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: "6%",
                right: "3%",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
            }}
        >
            {props.isOpen &&
                <Paper
                    sx={{
                        height: { md: 600, xs: '80vh' },
                        width: { md: 400, xs: '90vw' },
                        borderRadius: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'chat.background',
                    }}
                >
                    <Box
                        sx={{
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            backgroundColor: 'primary.dark',
                            height: "10%",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography variant='h6'>
                            Chat AI
                        </Typography>
                    </Box>

                    <Card
                        sx={{
                            display: 'flex',
                            maxHeight: "80%",
                            flexDirection: 'column',
                            flexGrow: 1,
                            overflow: 'auto',
                            backgroundColor: "grey.800",
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}
                    >
                        <div ref={lastRef}>
                            {props.messages.map((message, index) => {
                                if (message.role === 1) {
                                    return (
                                        <SentMessage
                                            key={"message" + index}
                                            text={message.content}
                                        />
                                    )
                                }
                                if (message.role === 2) {
                                    return (
                                        <ReceivedMessage
                                            key={"message" + index}
                                            text={message.content}
                                        />
                                    )
                                }
                            })}
                        </div>

                        <div ref={bottomRef} />

                    </Card>
                    {props.isLoading &&
                        <LinearProgress sx={{ width: '100%' }} />
                    }
                    <Card
                        sx={{
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            minHeight: '10%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            backgroundColor: "grey.800",
                        }}
                    >
                        <TextField
                            sx={{
                                width: '80%'
                            }}
                            id="standard-basic"
                            variant="standard"
                            multiline
                            placeholder="Kirjoita viesti"
                            onChange={(event) => setTextField(event.target.value)}
                            value={textField}
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                    // Do code here
                                    ev.preventDefault();
                                    handleSend();
                                }
                            }}
                        />
                        <SendIcon

                            onClick={handleSend}
                            color="primary"
                        />
                    </Card>
                </Paper>
            }
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <ChatBubbleIcon
                    onClick={toggle}
                    sx={{ fontSize: 40 }}
                    color="primary"
                />
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        messages: state.chat.messages,
        active: state.chat.active,
        isLoading: state.chat.isLoading,
        isOpen: state.chat.isOpen
    };
};

const mapDispatchToProps = (dispatch) => ({
    //getProducts: () => dispatch(ProductActions.getProducts()),
    clearMessages: () => dispatch(ChatActions.clearMessages()),
    setMessage: (message) => dispatch(ChatActions.setMessage(message)),
    setActive: (status) => dispatch(ChatActions.setActive(status)),
    setOpen: status => dispatch(ChatActions.setOpen(status)),
    sendMessage: (message, oldMessages, navigate) => dispatch(ChatActions.sendMessageRequest(message, oldMessages, navigate)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(PopupChat);