import React, { useState, useEffect } from 'react';

import Hero from '../components/sections/Hero';
import ProductPageSection from '../components/sections/ProductPageSection'
import Appbar from '../components/elements/Appbar';
import { useNavigate, useParams } from 'react-router';


import {
    Container
} from '@mui/material';

const ProductPage = (props) => {
    const navigate = useNavigate();
    const { lang } = useParams();
    function customNavigate(link) {
        navigate("/" + lang + "/shop")
    }

    return (
        <>
            <Appbar
                onSearchChange={(text) => customNavigate()}
            />
            <ProductPageSection />
        </>
    )
}

export default ProductPage;