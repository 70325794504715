import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga';

import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable';

const transformerConfig = {
    whitelistPerReducer: {
        products: ['shoppingCart'],
        chat: ['messages']
    },
};

const rootReducer = combineReducers({
    products: require('./ProductRedux').reducer,
    chat: require('./ChatRedux').reducer,
})

const persistConfig = {
    transforms: [seamlessImmutableTransformCreator(transformerConfig)],
    stateReconciler: seamlessImmutableReconciler,
    key: 'root',
    version: 1,
    storage,
    //blacklist: ['counter'], // What you don't wanna to persist
    //whitelist: ['products'] // What you want to persist
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware],
})

export const persistor = persistStore(store)