import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import FeatureTile from './partials/FeatureTile';
import CheckMobileScreen from '../../utils/CheckMobileScreen';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    Container,
    Typography,
    Box,
    Button,
    ButtonGroup,
} from '@mui/material';
import LangHref from '../../utils/LangHref';


const Features = (props) => {


    const ScrollTo = (toId) => {
        console.log("Clicked")
        const anchor = document.getElementById(toId)
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth" })
        } else {
            console.log("not found")
        }
    }

    return (
        <Container maxWidth='lg' >
            <Box display={'flex'} justifyContent={'center'}>
                <Typography variant='h6' textAlign={'center'} maxWidth={'md'}>
                    {props.t('home.description')}
                </Typography>
            </Box>
            <Box style={{ "margin": 20 }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                <ButtonGroup variant="text" orientation={CheckMobileScreen() ? 'vertical' : 'horizontal'}>
                    <Button onClick={() => ScrollTo('gatesTile')}>{props.t('home.gatesTile.title')}</Button>
                    <Button onClick={() => ScrollTo('productsTile')}>{props.t('home.productsTile.title')}</Button>
                    <Button onClick={() => ScrollTo('consultingTile')}>{props.t('home.consultingTile.title')}</Button>
                </ButtonGroup>
            </Box>
            <FeatureTile
                id='gatesTile'
                title={props.t('home.gatesTile.title')}
                text={props.t('home.gatesTile.text')}
                link={props.t('home.gatesTile.link')}
                linkRef={LangHref('/gates')}
                image={'/images/productImages/' + props.t('home.gatesTile.image.file')}
            />
            <FeatureTile
                id='productsTile'
                title={props.t('home.productsTile.title')}
                text={props.t('home.productsTile.text')}
                link={props.t('header.shop')}
                linkRef={LangHref('/shop') + '#reader_section'}
                image={'/images/shop_image.png'}
                reverse
            />
            <FeatureTile
                id='consultingTile'
                title={props.t('home.consultingTile.title')}
                text={props.t('home.consultingTile.text')}
                //link={props.t('home.consultingTile.link')}
                image={'/images/tech_image.png'}
            />
        </Container >
    )
}
const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Features);