import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import {
    Container,
    Grid,
    Typography,
    Box,
    CardContent,
    CardMedia,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    TextField,
    Button,
    ButtonGroup,
    Stack,
    Card
} from '@mui/material';



export const SentMessage = props => (
    <Box
        sx={{
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <Box sx={{
            //backgroundColor: "#0078fe",
            backgroundColor: "grey.900",
            padding: 2,
            //marginLeft: '45%',
            //borderRadius: 1,
            //marginBottom: 15,
            marginTop: 1,
            marginRight: "5%",
            maxWidth: '80%',
            alignSelf: 'flex-end',
            //maxWidth: 500,

            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            borderTopLeftRadius: 20,
        }}
        >
            <Typography sx={{ fontSize: 16, }} style={{ whiteSpace: 'pre-line' }}>{props.text}</Typography>

        </Box>
    </Box>
)

export const ReceivedMessage = props => (
    //Recevied Message
    <Box sx={{
        backgroundColor: "grey.900",
        padding: 2,
        //borderRadius: 5,
        marginTop: 1,
        marginLeft: "5%",
        maxWidth: '80%',
        alignSelf: 'flex-start',
        //maxWidth: 500,
        //padding: 14,

        //alignItems:"center",
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderTopRightRadius: 20,
    }}
    >
        <Typography sx={{ fontSize: 16, justifyContent: "center" }} style={{ whiteSpace: 'pre-line' }}> {props.text}</Typography>
    </Box>

)