import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
    Container,
    Grid,
    Typography,
    Box,
    CardContent,
    CardMedia,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    TextField,
    Button,
    ButtonGroup,
    Stack,
    Card
} from '@mui/material';

const Footer = (props) => {
    const { t } = useTranslation();
    return (
        <Card
            id='footer'
            sx={{
                width: "100%",
                height: "auto",
                //backgroundColor: "background.paper",
                paddingTop: "1rem",
                //paddingBottom: "2rem",
                borderTop: "1px solid #000",
                marginTop: "auto",
                p: 4,
            }}
        >
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography component={'p'} variant="subtitle1">
                            {t('footer.email')}: {t('footer.emailAdress')}
                        </Typography>
                        <Typography component={'p'} variant="subtitle1">
                            {t('footer.phone')}: +358 40 6206964
                        </Typography>
                    </Grid>

                    <Grid item xs={12} justifyContent='right' >
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                        >
                            <Box >
                                <Typography component={'p'} color="textSecondary" variant="subtitle1">
                                    Gatech - PS Enterprise Oy 3395168-9
                                </Typography>
                            </Box>
                            <Box >
                                {false &&
                                    <Button >
                                        {t('footer.privacy')}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" component={'p'} variant="subtitle1" style={{ flex: 1, textAlign: "center" }}>
                            {`${new Date().getFullYear()} | All rights reserved`}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Card>
    );
}

export default Footer;