import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    isLoading: false,
    products: null,
    shoppingCart: [],
    errorMessage: null
});


const { Types, Creators } = createActions({
    getProducts: null,
    getProductsSuccess: ['products'],
    getProductsFailure: ['errorMessage'],

    addToShoppingCart: ['product'],
    updateShoppingCart: ['product'],
    clearShoppingCart: null

})

export const getProducts = (state) =>
    state.merge({ isLoading: true, errorMessage: null });

export const getProductsSuccess = (state, { products }) =>
    state.merge({ isLoading: false, products });


export const getProductsFailure = (state, { errorMessage }) =>
    state.merge({ isLoading: false, errorMessage });


export const addToShoppingCart = (state, { product }) => {
    let found = false;

    const items = state.shoppingCart.map(prod => {
        if (prod.id === product.id) {
            console.log("prod.id ", prod.id)
            console.log("product.id ", product.id)
            found = true;
            return { id: prod.id, amount: Number(prod.amount) + Number(product.amount) }
        }
        return prod
    })
    if (found === true) {
        return state.merge({ shoppingCart: items })
    }

    return (
        state.merge({ shoppingCart: [...state.shoppingCart, product] })
    )
}

export const updateShoppingCart = (state, { product }) => {

    const items = state.shoppingCart.map(prod => {
        if (prod.id === product.id) {
            console.log("prod.id ", prod.id)
            console.log("product.id ", product.id)
            return { id: prod.id, amount: Number(product.amount) }
        }
        return prod
    })
    return state.merge({ shoppingCart: items })

}

export const clearShoppingCart = (state) =>
    state.merge({ shoppingCart: [] });


export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_PRODUCTS]: getProducts,
    [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
    [Types.GET_PRODUCTS_FAILURE]: getProductsFailure,

    [Types.ADD_TO_SHOPPING_CART]: addToShoppingCart,
    [Types.UPDATE_SHOPPING_CART]: updateShoppingCart,
    [Types.CLEAR_SHOPPING_CART]: clearShoppingCart,
})

export const ProductTypes = Types;
export default Creators;