import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    isLoading: false,
    active: false,
    messages: [],
    errorMessage: null,
    userid: null,
    isOpen: false
});


const { Types, Creators } = createActions({
    setActiveState: ['status'],
    setOpen: ['status'],
    setMessage: ['message'],
    clearMessages: null,
    sendMessageRequest: ['message', 'oldMessages', 'navigate'],
    sendMessageError: ['errror'],
    sendMessageSuccess: ['message'],

})
export const setOpen = (state, { status }) => state.merge({ isOpen: status });
export const setActiveState = (state, { status }) => state.merge({ active: status });
export const setMessage = (state, { message }) => state.merge({ messages: [...state.messages, message], isOpen: true });

export const sendMessageRequest = (state, { message, oldMessages, navigate }) => state.merge({ isLoading: true, isOpen: true });
export const sendMessageError = (state, { error }) => state.merge({ isLoading: false, errorMessage: error });
export const sendMessageSuccess = (state, { message }) => state.merge({ isLoading: false, messages: [...state.messages, message] });

export const clearMessages = (state) =>
    state.merge({ messages: [] });


export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_ACTIVE_STATE]: setActiveState,
    [Types.SET_OPEN]: setOpen,
    [Types.SET_MESSAGE]: setMessage,
    [Types.CLEAR_MESSAGES]: clearMessages,
    [Types.SEND_MESSAGE_REQUEST]: sendMessageRequest,
    [Types.SEND_MESSAGE_ERROR]: sendMessageError,
    [Types.SEND_MESSAGE_SUCCESS]: sendMessageSuccess,
})

export const ChatTypes = Types;
export default Creators;