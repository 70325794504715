import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import FeatureTile from './partials/FeatureTile';
import CheckMobileScreen from '../../utils/CheckMobileScreen';
//import GatechLogo from '../../assets/images/gatech_logo.svg'
import {
    Container,
    Typography,
    Box,
} from '@mui/material';
const Hero = (props) => {


    return (
        <Container maxWidth='md' >
            <Box sx={{ "margin": 5 }}>
                <Box
                    container='true'
                    spacing={2}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexWrap={'wrap'}
                >
                    {props.small ?
                        <Typography variant="h2">
                            Gatech
                        </Typography>
                        :
                        <img
                            src={'/images/gatech_logo.svg'}
                            alt="Hero"
                            width={300}
                        />
                    }
                </Box>
            </Box>
        </Container >
    )
}

export default Hero;