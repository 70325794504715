import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartDialog from './ShoppingCartDialog';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LangHref from '../../utils/LangHref';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ChangeLanguage } from '../../utils/LanguagePath';
import { useNavigate, useLocation } from 'react-router-dom';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import GatechIcon from '../../assets/images/GatechIcon';


//const pages = ['solutions','gates', 'shop', 'blog'];
const pages = ['solutions', 'gates', 'generator', 'shop'];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Appbar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const ScrollTo = (toId) => {
        const anchor = document.getElementById(toId)
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth" })
        } else {
            console.log("not found")
        }
    }

    const handleLanguageChange = (event) => {
        ChangeLanguage(event.target.value);
    };

    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <GatechIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to={LangHref('/')}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            boxShadow: "none"
                        }}
                    >
                        Gatech
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    component={Link}
                                    to={(page === 'solutions' ? LangHref('/') : LangHref("/" + page))}
                                >
                                    <Typography textAlign="center">{t('header.' + page)}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                component={Link}
                                to={(page === 'solutions' ? LangHref('/') : LangHref("/" + page))}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {t('header.' + page)}
                            </Button>
                        ))}
                    </Box>

                    {!props.hideSearch &&
                        <>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={e => props.onSearchChange(e.target.value)}
                                />
                            </Search>
                            {false &&
                                <Box sx={{ flexGrow: 0 }}>
                                    <ShoppingCartDialog />
                                </Box>
                            }
                        </>
                    }
                    <Button
                        onClick={() => ScrollTo('footer')}
                        sx={{ my: 2, color: 'white', display: { md: 'block', xs: 'none' } }}
                    >
                        {t('header.contact')}
                    </Button>
                    <IconButton
                        size="large"
                        aria-label="contact details"
                        onClick={() => ScrollTo('footer')}
                        color="inherit"
                        sx={{ display: { md: 'none' } }}
                    >
                        <ContactMailIcon />
                    </IconButton>

                    <FormControl sx={{ marginTop: 1, marginBottom: 1, minWidth: 69 }} size="small">
                        <Select
                            value={i18n.resolvedLanguage}
                            onChange={event => ChangeLanguage(event.target.value, i18n, navigate, location)}
                        >
                            <MenuItem value={'en'}>EN</MenuItem>
                            <MenuItem value={'fi'}>FI</MenuItem>
                            <MenuItem value={'sv'}>SE</MenuItem>
                        </Select>
                    </FormControl>



                </Toolbar>
            </Container>
        </AppBar >
    );
}
export default Appbar;