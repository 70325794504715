import React, { useState, useEffect } from 'react';

import Hero from '../components/sections/Hero';
import ShopProducts from '../components/sections/ShopProducts';
import Appbar from '../components/elements/Appbar';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function LangHref(link) {
    const { lang } = useParams();
    return "/" + lang + link
}

export default LangHref;