import apisauce from 'apisauce';
import axios from 'axios'
//import { version } from '../../package.json';


const baseUrl = process.env.REACT_APP_API_URL;
const blobBaseUrl = process.env.REACT_APP_BLOB_API_URL;

console.log('env.API_URL:', baseUrl);

const ENDPOINT_PSE_PRODUCTS = 'api/stripe'
const ENDPOINT_PSE_CREATE_INVOICE = 'api/stripe/invoice'
const ENDPOINT_SEND_AI_MESSAGE = 'api/chatAi/message'
const ENDPOINT_BLOB = 'public/products.json'

const apiConfig = {
    timeout: 40000,
    baseURL: baseUrl,
    //withCredentials: true,
    headers: {
        //'Cache-Control': 'no-cache',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        //'Version': version,
        'Version': process.env.REACT_APP_VERSION
    },
};

const blobApiConfig = {
    timeout: 40000,
    baseURL: blobBaseUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
};

const createApi = () => {
    const api = apisauce.create(apiConfig);
    const blobApi = apisauce.create(blobApiConfig);

    const getPseProducts = () => blobApi.get(`${ENDPOINT_BLOB}`);
    const createPseInvoice = (items) => api.post(`${ENDPOINT_PSE_CREATE_INVOICE}`, items);
    const sendAiMessage = message => api.put(`${ENDPOINT_SEND_AI_MESSAGE}`, message);

    return {
        getPseProducts,
        createPseInvoice,
        sendAiMessage,
    }
};

export default {
    createApi,

};