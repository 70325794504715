import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

const SelectComponent = ({ items, value, handleChange, label, langRoot }) => {
    const { t } = useTranslation();
    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel >{label}</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
                label={label}
            >
                {items.map((i, index) => (
                    <MenuItem value={i} key={"select" + label + index}>{t(langRoot + '.select.' + i)}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}


export default SelectComponent;