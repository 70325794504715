import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, sagaMiddleware } from './redux/store'
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import rootSaga from './sagas';

const history = createBrowserHistory();
//<Router history={history}>
sagaMiddleware.run(rootSaga);
const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate
      loading={<div>loading...</div>}
      persistor={persistor}
    >
      <Router history={history} location={history.location} navigator={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider >
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
