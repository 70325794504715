import { call, put, select } from 'redux-saga/effects';
import ProductActions from '../redux/ProductRedux';

export function* getProducts(api, { }) {
    try {
        const response = yield api.getPseProducts();
        console.log('product response:', response);
        if (response.ok) {
            yield put(
                ProductActions.getProductsSuccess(
                    response.data
                )
            );
        } else {
            yield put(ProductActions.getProductsFailure(response.data ? response.data : response.problem));
        }
    } catch (error) {
        console.log("auth error: " + error);
        yield put(ProductActions.getProductsFailure(error));
    }
}