import React, { useState, useEffect, useRef } from 'react';
import { call, put, select } from 'redux-saga/effects';
import ChatActions from '../redux/ChatRedux';
import LangHref from '../utils/LangHref';

export function* sendMessage(api, { message, oldMessages, navigate }) {

    try {
        const response = yield api.sendAiMessage({
            message: message,
            oldMessages: oldMessages
        });
        console.log('message response:', response);
        if (response.ok) {
            yield put(
                ChatActions.sendMessageSuccess(
                    response.data
                )
            );
            if (response.data.link) {
                navigate(response.data.link);
            }
        } else {
            yield put(ChatActions.sendMessageError(response.data ? response.data : response.problem));
        }
    } catch (error) {
        console.log("auth error: " + error);
        yield put(ChatActions.sendMessageError(error));
    }
}