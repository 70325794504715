import React, { useEffect, Suspense } from 'react';
import { useLocation, Routes, BrowserRouter as Router, Route, Navigate } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import GatechLogo from './assets/images/gatech_logo_black.svg'


// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Shop from './views/Shop';
import Gates from './views/Gates'
import ProductPage from './views/ProductPage';
//import CheckoutView from './views/CheckoutView';
import Generator from './views/Generator';
import LanguagePath from './utils/LanguagePath';
import { HelmetProvider } from 'react-helmet-async';


// Initialize Google Analytics
//ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize('G-XDTHLM0WM9');

const trackPage = page => {
  ReactGA.set({ page });
  //ReactGA.pageview(page);
  ReactGA.send({ hitType: "pageview", page: page, title: page });
  console.log("trackpage: ", page)
};

export const baseUrl = () => (window.location.hostname);

const App = () => {


  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const page = location.pathname;
    document.body.classList.add('is-loaded')

    trackPage(page);
  }, [location]);

  useEffect(() => {

    console.log(`Version ${process.env.REACT_APP_VERSION}`)

  }, []);

  return (

    <Suspense fallback={
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }} >
        <img src={GatechLogo} alt={"Gatech Icon"} />
      </div>}>
      <HelmetProvider>
        <Routes>
          <Route path="*" element={<LanguagePath />} />
          <Route path=":lang" element={<LanguagePath />}>

            <Route index element={<AppRoute component={Home} layout={LayoutDefault} />} />
            <Route strict path="product/:productId" element={<AppRoute component={ProductPage} layout={LayoutDefault} />} />
            <Route strict path="gates" element={<AppRoute component={Gates} layout={LayoutDefault} />} />
            <Route strict path="shop" element={<AppRoute component={Shop} layout={LayoutDefault} />} />

            <Route strict path="generator" element={<AppRoute component={Generator} layout={LayoutDefault} />} />

            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </HelmetProvider>
    </Suspense>

  );
}

export default App;