
const ScrollTo = (toId) => {
    const anchor = document.getElementById(toId)
    const yOffset = -70;
    if (anchor) {
        //anchor.scrollIntoView({ behavior: "smooth" })
        const y = anchor.getBoundingClientRect().top + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
        console.log("not found")
    }
}

export default ScrollTo;