import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../components/elements/Footer';
import { Box, Button } from '@mui/material';
import PopupChat from '../components/Popchat/PopupChat';
import { alpha } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import CookieConsent from "react-cookie-consent";


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        buy: {
            main: '#1CB68B',
        },
        chat: {
            background: "#969696",
            bubble: alpha("#00", 0.1),
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: "#6b6b6b #2b2b2b",
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "#2b2b2b",
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 8,
                        backgroundColor: "#6b6b6b",
                        minHeight: 24,
                        border: "3px solid #2b2b2b",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        backgroundColor: "#2b2b2b",
                    },
                },
            },
        },
    },
});

const LayoutDefault = ({ children }) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    }}
                >
                    {children}
                    <Footer />

                    <PopupChat />
                </Box>
                <CookieConsent
                    location="bottom"
                    buttonText={t('cookieBanner.button')}
                    ButtonComponent={Button}
                    cookieName="gatechCookie"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ background: "#2B373B", color: "#90caf9", fontSize: "13px" }}
                    expires={150}
                >
                    {t('cookieBanner.title')}
                </CookieConsent>
            </ThemeProvider>

            {
                //<Banner />
                //<Footer />
            }

        </>
    )
};

export default LayoutDefault;  