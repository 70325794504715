import API from '../services/Api'
import { takeLatest, takeEvery, all } from 'redux-saga/effects';
import { ProductTypes } from '../redux/ProductRedux';
import { ChatTypes } from '../redux/ChatRedux';
import { getProducts } from './ProductSagas';
import { sendMessage } from './ChatSagas';

export const api = API.createApi();


export default function* rootSaga() {
    yield all([
        takeLatest(ProductTypes.GET_PRODUCTS, getProducts, api),
        takeEvery(ChatTypes.SEND_MESSAGE_REQUEST, sendMessage, api),
    ])
}