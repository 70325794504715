import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { TextField, Stack, Box } from '@mui/material';
import ProductActions from '../../redux/ProductRedux';


import Tooltip from '@mui/material/Tooltip';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';


function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;
    const navigate = useNavigate();

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Ostokset</DialogTitle>
            <List sx={{ pt: 0, padding: 3 }}>
                {props.shoppingCart?.map((prod) => (
                    <ListItem disableGutters key={"item" + prod.id}>
                        <Stack>
                            <Stack
                                direction="row"
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'flex-end'}
                                spacing={2}
                            >
                                <ListItemText primary={prod.name} />
                                <Typography color='buy.main' >
                                    {(prod?.price?.unitAmountDecimal / 100 * prod.amount).toFixed(2)} €
                                </Typography>
                            </Stack>
                            <TextField
                                style={{ width: 100, minWidth: 100 }}
                                id="outlined-number"
                                label="Kpl"
                                type="number"
                                size="small"
                                variant="standard"
                                onChange={e => props.updateShoppingCart({ id: prod.id, amount: e.target.value >= 0 ? e.target.value : 0 })}
                                value={prod.amount}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Stack>
                    </ListItem>
                ))}

            </List>
            <Button
                onClick={() => navigate("/checkout")}
            >
                Jatka kassalle
            </Button>
            <Button color='error' onClick={() => { props.clearShoppingCart(); handleClose() }}>
                Tyhjennä
            </Button>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

const ShoppingCartDialog = (props) => {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <>
            <Tooltip title="Ostokset">
                {/*<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>*/}
                <IconButton onClick={handleClickOpen} sx={{ p: 0 }}>
                    <ShoppingCartIcon />
                </IconButton>
            </Tooltip>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                shoppingCart={props.shoppingCart}
                clearShoppingCart={props.clearShoppingCart}
                updateShoppingCart={props.updateShoppingCart}
            />
        </>
    );
}

const mapStateToProps = (state) => {

    const findProduct = (id) => (state.products.products?.find(i => i.id === id))

    const shoppingCart = state.products?.shoppingCart?.map(s =>
    (
        { amount: s.amount, ...findProduct(s.id) }
    ))

    console.log("ShoppingCart: ", state.products?.shoppingCart)

    return {
        products: state.products.products,
        shoppingCart: shoppingCart
    };
};

const mapDispatchToProps = (dispatch) => ({
    //getProducts: () => dispatch(ProductActions.getProducts()),
    clearShoppingCart: () => dispatch(ProductActions.clearShoppingCart()),
    updateShoppingCart: (product) => dispatch(ProductActions.updateShoppingCart(product)),
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ShoppingCartDialog);