import React, { memo, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ProductActions from '../../redux/ProductRedux';

import { withTranslation, useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Fade } from "react-awesome-reveal";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Divider, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import LangHref from '../../utils/LangHref';
import CheckMobileScreen from '../../utils/CheckMobileScreen';
import ScrollTo from '../../utils/ScrollTo';
import ShopTreeView from '../shopTreeView'
//import Link from '@mui/material/Link';

//<div className="tiles-item reveal-from-bottom">
const ShopItem = memo(({ image, title, description, price, isLoaded, id }) => {
    //const [isLoaded, setIsloaded] = useState(false);
    const { t, i18n } = useTranslation(['translation', 'products']);
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: 420 }} >
            <CardContent sx={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 2, minHeight: 216 }}>
                    <img
                        style={{ borderRadius: '10%' }}
                        src={image}
                        alt={title}
                        width={200}
                        onLoad={() => { isLoaded() }}
                    />
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {i18n.exists('products:' + id + '.title') ? t('products:' + id + '.title') : description}
                </Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', alignContent: 'flex-end', justifyContent: 'space-between', marginTop: 'auto' }}>
                {price &&
                    <Typography color={'#1CB68B'} variant='subtitle1'>
                        {(price / 100).toFixed(2)} € (alv. 0%)
                    </Typography>
                }
                <Link align="right"
                    style={{ alignSelf: 'right', marginRight: 20, color: '#1CB68B', textDecoration: 'none' }}
                    to={LangHref('/product/' + id)}>
                    {price ? t('translation:shop.buy') : t('translation:shop.readMore')}
                </Link>
            </CardActions>
        </Card>
    )
})

const Skeletons = () => {
    const skeletonArray = [...Array(4)]

    const SkeletonItem = () => (
        <Stack spacing={1}>
            <Skeleton variant="circular" width={200} height={200} />
            <Skeleton variant="rounded" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
        </Stack>
    )

    return (
        skeletonArray.map((e, i) => (
            <Grid item xs={3} style={{ minWidth: 273 }} key={"skeleton" + i}>
                <SkeletonItem />
            </Grid>
        ))
    )
}

const ShowProducts = memo(({ i18n, t, prod, setLoadedImageCount }) => {
    const imageExists = (i18n.exists('products:' + prod.id) && t('products:' + prod.id + '.images', { returnObjects: true }).length > 0)
    let prodImage = '/images/productImages/Product-Image-Coming-Soon.png'
    if (imageExists) {
        prodImage = '/images/productImages/' + t('products:' + prod.id + '.images', { returnObjects: true })[0].file
    }


    return (
        <Grid item xs={3} style={{ minWidth: 273 }}>
            < ShopItem
                id={prod.id}
                image={prodImage}
                title={imageExists ? t('products:' + prod.id + '.name') : prod.name}
                description={prod.description}
                price={prod.price?.unitAmountDecimal}
                isLoaded={() => { setLoadedImageCount(c => c + 1); console.log("image loaded") }}
            />
        </Grid>
    )

})

const ShopItems = (props) => {

    const [loadedImageCount, setLoadedImageCount] = useState(0);
    const [filteredProducts, setFilteredProducts] = useState([])

    useEffect(() => {
        console.log("props ", props);
        props.getProducts();
    }, []);


    const comingSoonImage = '/images/productImages/Product-Image-Coming-Soon.png'


    useEffect(() => {
        console.log("loaded ", loadedImageCount)
        console.log("products ", props.products?.length)
    }, [loadedImageCount])


    useEffect(() => {
        if (props.searchTerm === '') {
            setFilteredProducts(props.products)
        } else {
            setFilteredProducts(props.products?.filter(p => p.name.toLowerCase().includes(props.searchTerm.toLowerCase())))
        }
    }, [props.searchTerm, props.products])

    return (
        <Container maxWidth='lg' sx={{ paddingBottom: 2 }}>
            {(loadedImageCount < props.products?.length || props.products === null) &&
                <Box >
                    <Grid container spacing={2} justifyContent={'center'} display={'flex'}>
                        <Skeletons />
                    </Grid>
                </Box>
            }

            <div hidden={loadedImageCount < props.products?.length || props.products === null}>
                <Box style={{ "margin": 20 }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexWrap={'wrap'}
                >
                    <ButtonGroup variant="text" orientation={CheckMobileScreen() ? 'vertical' : 'horizontal'} color="buy">
                        <Button onClick={() => ScrollTo('gate_section')}>{props.t('shop.gate')}</Button>
                        <Button onClick={() => ScrollTo('reader_section')}>{props.t('shop.reader')}</Button>
                        <Button onClick={() => ScrollTo('tag_section')}>{props.t('shop.tag')}</Button>
                    </ButtonGroup>
                </Box>
                <Box >
                    {(props.products /*&& loadedImageCount === props.products?.length*/) &&
                        <>
                            <Divider id="gate_section" textAlign="left">{props.t('shop.gate')}</Divider>
                            <Grid container spacing={2} justifyContent={'center'} display={'flex'} sx={{ marginTop: 2, marginBottom: 2 }}>
                                {
                                    filteredProducts?.filter(p => p.category === 'gate').map(prod => (
                                        <ShowProducts
                                            key={prod.id}
                                            i18n={props.i18n}
                                            t={props.t}
                                            prod={prod}
                                            setLoadedImageCount={setLoadedImageCount}

                                        />
                                    ))
                                }
                            </Grid>
                            <Divider id="reader_section" textAlign="left">{props.t('shop.reader')}</Divider>
                            <Grid container spacing={2} justifyContent={'center'} display={'flex'} sx={{ marginTop: 2, marginBottom: 2 }}>
                                {
                                    filteredProducts?.filter(p => p.category === 'reader').map(prod => (
                                        <ShowProducts
                                            key={prod.id}
                                            i18n={props.i18n}
                                            t={props.t}
                                            prod={prod}
                                            setLoadedImageCount={setLoadedImageCount}

                                        />
                                    ))
                                }
                            </Grid>
                            <Divider id="tag_section" textAlign="left">{props.t('shop.tag')}</Divider>
                            <Grid container spacing={2} justifyContent={'center'} display={'flex'} sx={{ marginTop: 2, marginBottom: 2 }}>
                                {
                                    filteredProducts?.filter(p => p.category === 'tag').map(prod => (
                                        <ShowProducts
                                            key={prod.id}
                                            i18n={props.i18n}
                                            t={props.t}
                                            prod={prod}
                                            setLoadedImageCount={setLoadedImageCount}

                                        />
                                    ))
                                }
                            </Grid>
                            <Divider id="other_section" textAlign="left">{props.t('shop.other')}</Divider>
                            <Grid container spacing={2} justifyContent={'center'} display={'flex'} sx={{ marginTop: 2, marginBottom: 2 }}>
                                {
                                    filteredProducts?.filter(p => p.category === 'other').map(prod => (
                                        <ShowProducts
                                            key={prod.id}
                                            i18n={props.i18n}
                                            t={props.t}
                                            prod={prod}
                                            setLoadedImageCount={setLoadedImageCount}

                                        />
                                    ))
                                }
                            </Grid>
                        </>
                    }

                </Box>
            </div>

        </Container>
    );
}

//ShopItems.propTypes = propTypes;
//ShopItems.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    return {
        products: state.products.products
    };
};

const mapDispatchToProps = (dispatch) => ({
    getProducts: () => dispatch(ProductActions.getProducts()),
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(['translation', 'products'])
)(ShopItems);