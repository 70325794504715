import React, { useState, useEffect } from 'react';

import Hero from '../components/sections/Hero';
import ShopProducts from '../components/sections/ShopProducts';
import Appbar from '../components/elements/Appbar';
import { Helmet } from "react-helmet-async";
import HelmetHelper from '../utils/HelmetHelper';

const Shop = (props) => {
    const [searchTerm, setSearchTerm] = useState('');


    return (
        <>
            <HelmetHelper
                page={'shop'}
            />
            <Appbar
                onSearchChange={(text) => setSearchTerm(text)}
            />
            <Hero />
            <ShopProducts
                searchTerm={searchTerm}
            />
        </>
    )
}

export default Shop;