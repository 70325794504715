import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';

export default function LanguagePath() {
    const { i18n } = useTranslation();
    const { lang } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const curPath = location.pathname;
    useEffect(() => {
        if (lang && i18n.resolvedLanguage !== lang) {
            console.log("lang ", lang)
            if (i18n.options.fallbackLng.includes(lang)) {
                i18n.changeLanguage(lang);
                console.log("lang includes")
            } else {
                navigate("/" + i18n.resolvedLanguage + curPath, { replace: true });
                console.log("lang not include")
            }
        } else if (!lang) {
            navigate("/" + i18n.resolvedLanguage + curPath, { replace: true });
            console.log("lang not found curpath ", curPath)
        }
    }, [lang]);
    return <Outlet />;
}

export const ChangeLanguage = (lang, i18n, navigate, location) => {

    //const { i18n } = useTranslation();
    //const navigate = useNavigate();
    //const location = useLocation();
    const curPath = location.pathname;
    const newPath = "/" + lang + curPath.substring(3)
    i18n.changeLanguage(lang);
    navigate(newPath, { replace: true })
}