import React, { memo, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
//import { Link } from 'react-router-dom';
import ProductActions from '../../redux/ProductRedux';

import ShopTreeView from '../shopTreeView';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CheckMobileScreen from '../../utils/CheckMobileScreen';
import { useNavigate } from "react-router-dom";
import ChatActions from '../..//redux/ChatRedux'
import { Helmet } from "react-helmet-async";
import {
    Container,
    Grid,
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Link,
    TextField,
    Button,
    ButtonGroup,
    Stack,
    ImageList,
    ImageListItem
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';


const ProductPageSection = (props) => {
    const { t, i18n } = props
    const [loadedImage, setLoadedImage] = useState(false);
    const [buyCount, setBuyCount] = useState(1);
    const { productId, lang } = useParams()
    const navigate = useNavigate();
    //console.log("product: ", product)

    useEffect(() => {
        console.log("props ", props);
        props.getProducts();
    }, []);

    const product = props.products?.find(e => e.id === productId)

    const translationExists = i18n.exists('products:' + productId);
    const getT = (name) => (t('products:' + productId + '.' + name))

    //const comingSoonImage = require('../../assets/images/productImages/Product-Image-Coming-Soon.png')
    const imageExists = (translationExists && t('products:' + productId + '.images', { returnObjects: true }).length > 0)

    //console.log("features ", t('products:' + productId + '.features', { returnObjects: true }))

    const ScrollTo = (toId) => {
        console.log("Clicked")
        const anchor = document.getElementById(toId)
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth" })
        } else {
            console.log("not found")
        }
    }
    const checkMobile = CheckMobileScreen();

    const requestContact = () => {
        const message = t('chat.offerRequest').replace('{productName}', product?.name).replace('{productId}', productId);
        props.setMessage({ role: 1, content: message, functionCall: 'request_contact' });
        props.sendMessage(message, props.messages, navigate);
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://gatech.fi/" + lang + "/product/" + productId} />
                <title>{translationExists ? t('products:' + productId + '.name') + " - Gatech" : product?.name + " - Gatech"}</title>
                <meta name="description" content={translationExists ? t('products:' + productId + '.title') : product?.description} />
                {i18n.options.fallbackLng.map(lan => (
                    <link href={"https://gatech.fi/" + lan + "/product/" + productId} rel="alternate" hreflang={lan} key={lan} />
                ))}
            </Helmet>
            <Container maxWidth='lg' sx={{ p: 0, marginTop: 2, marginBottom: 2 }}>
                <Box display={'flex'} flexDirection={'row'}>
                    <Box marginRight={4} display={{ xs: 'none', md: 'flex' }}>
                        <ShopTreeView />
                    </Box>
                    <Grid container spacing={2} justifyContent={'center'} display={'flex'}>

                        <Grid item xs={8}>
                            <Typography variant='h3' component='h1'>
                                {translationExists ? t('products:' + productId + '.name') : product?.name}
                            </Typography>
                            <Typography style={{ marginTop: 20 }} variant='h5' component='h2' color={'buy.main'}>
                                {translationExists ? t('products:' + productId + '.title') : product?.description}
                            </Typography>
                            <Typography style={{ marginTop: 20 }} variant='body1' >
                                {getT('description')}
                            </Typography>
                            <Typography style={{ marginTop: 20 }} variant='h6' color={'buy.main'}>
                                {t('translation:shop.features')}
                            </Typography>
                            <List style={{ marginTop: 20 }}>
                                {translationExists &&
                                    t('products:' + productId + '.features', { returnObjects: true })?.map((f, index) =>
                                        <ListItem disablePadding key={"featureid" + index}>

                                            <ListItemIcon>
                                                <StarIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={f} />
                                        </ListItem>
                                    )}
                            </List>
                            <ButtonGroup color='buy' variant="outlined" orientation={checkMobile ? 'vertical' : 'horizontal'} sx={{ display: 'flex', margin: 2, justifyContent: 'center' }}>
                                <Button onClick={() => ScrollTo('technicalInfo')}>{props.t('shop.technicalInfo')}</Button>
                                <Button onClick={() => ScrollTo('productInfo')}>{props.t('shop.productInfo')}</Button>
                            </ButtonGroup>
                            <Typography style={{ marginTop: 20 }} variant='h6' color={'buy.main'} id='technicalInfo'>
                                {props.t('shop.technicalInfo')}
                            </Typography>

                            <TableContainer style={{ marginTop: 20 }}>
                                <Table sx={{ maxWidth: 800 }} aria-label="simple table">
                                    <TableBody>
                                        {t('products:' + productId + '.technicals', { returnObjects: true })?.map((tech, index) =>
                                            <TableRow
                                                key={"tech" + index}
                                            //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {tech.name}
                                                </TableCell>
                                                <TableCell >
                                                    {tech.value}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography style={{ marginTop: 20 }} variant='h6' color={'buy.main'} id='productInfo'>
                                {props.t('shop.productInfo')}
                            </Typography>
                            {product?.metadata &&
                                <>
                                    <TableContainer style={{ marginTop: 20 }}>
                                        <Table sx={{ maxWidth: 300 }} aria-label="simple table">
                                            <TableBody>
                                                {Object.keys(product?.metadata).map(key => {
                                                    if (!i18n.exists('shop.metadata.' + key)) return;
                                                    return (
                                                        <TableRow
                                                            key={key}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {t('shop.metadata.' + key)}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {product?.metadata[key].startsWith("http") ?
                                                                    <Link href={product?.metadata[key]} target="_blank">Avaa</Link>
                                                                    :
                                                                    product?.metadata[key]}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            }

                        </Grid>
                        <Grid item xs={4} style={{ minWidth: 250 }} sx={{ paddingRight: 2 }}>
                            {imageExists ?
                                <ImageList sx={{ minWidth: 250, padding: 5 }} cols={2} >
                                    {t('products:' + productId + '.images', { returnObjects: true }).map((item, index) => {
                                        //console.log("loading image: ", item)

                                        try {
                                            const listImage = '/images/productImages/' + item.file;
                                            return (
                                                <ImageListItem key={item.file} cols={index === 0 ? 2 : 1} rows={index === 0 ? 2 : 1}>
                                                    <img
                                                        src={listImage}
                                                        alt={item.name}
                                                        loading="lazy"
                                                        height={200}
                                                    />
                                                </ImageListItem>
                                            )
                                        } catch (ex) {
                                            //console.warn("problem when loading ", item.file)
                                            //console.warn(ex)
                                        }
                                    }
                                    )}
                                </ImageList>
                                :
                                <div hidden={!loadedImage}>
                                    <img
                                        src={product?.images?.length > 0 ? product.images[0] : ''}
                                        alt={product?.name}
                                        width={200}
                                        onLoad={() => { setLoadedImage(true) }}
                                    />
                                </div>
                            }
                            <Box
                                display={'flex'}
                                alignContent={'center'}
                                justifyContent={'center'}
                            >
                                <Button variant="outlined" color="buy"
                                    onClick={() => requestContact()}
                                >
                                    {t('shop.askQuotation')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        products: state.products.products,
        messages: state.chat.messages,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getProducts: () => dispatch(ProductActions.getProducts()),
    addToShoppingCart: (product) => dispatch(ProductActions.addToShoppingCart(product)),
    sendMessage: (message, oldMessages, navigate) => dispatch(ChatActions.sendMessageRequest(message, oldMessages, navigate)),
    setMessage: (message) => dispatch(ChatActions.setMessage(message)),
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(['translation', 'products'])
)(ProductPageSection);