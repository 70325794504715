import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

const ShopTreeView = (props) => {
    const [expanded, setExpanded] = useState(['gateNode', 'speedGateNode', 'swingGateNode', 'fullHeightTurnstileNode', 'tripodGateNode', 'readerNode', 'tagNode']);
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const { lang } = useParams();

    const handleToggle = (event, nodeIds) => {
        console.log("toggle", nodeIds)
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        console.log("select", nodeIds)
        setSelected(nodeIds);
        if (props.products.some(p => p.id === nodeIds[0])) {
            console.log("found", nodeIds[0])
            console.log("navigate", "/" + lang + '/product/ ' + nodeIds[0])
            navigate("/" + lang + '/product/' + nodeIds[0])
        }
    };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
        );
    };

    const handleSelectClick = () => {
        setSelected((oldSelected) =>
            oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
        );
    };

    return (
        <TreeView
            aria-label="controlled"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            multiSelect
        >
            <TreeItem nodeId="gateNode" label={props.t('shop.gate')}>
                <TreeItem nodeId="speedGateNode" label={props.t('shop.speedGate')}>
                    {props.products?.filter(p => p.category === 'gate' && p.subCategory === 'speedGate').map((prod, index) => (
                        <TreeItem nodeId={prod.id} label={props.t('products:' + prod.id + '.name')} />
                    ))}
                </TreeItem>
                <TreeItem nodeId="swingGateNode" label={props.t('shop.swingGate')}>
                    {props.products?.filter(p => p.category === 'gate' && p.subCategory === 'swingGate').map((prod, index) => (
                        <TreeItem nodeId={prod.id} label={props.t('products:' + prod.id + '.name')} />
                    ))}
                </TreeItem>
                <TreeItem nodeId="fullHeightTurnstileNode" label={props.t('shop.fullHeightTurnstile')}>
                    {props.products?.filter(p => p.category === 'gate' && p.subCategory === 'fullHeightTurnstile').map((prod, index) => (
                        <TreeItem nodeId={prod.id} label={props.t('products:' + prod.id + '.name')} />
                    ))}
                </TreeItem>
                <TreeItem nodeId="tripodGateNode" label={props.t('shop.tripodGate')}>
                    {props.products?.filter(p => p.category === 'gate' && p.subCategory === 'tripodGate').map((prod, index) => (
                        <TreeItem nodeId={prod.id} label={props.t('products:' + prod.id + '.name')} />
                    ))}
                </TreeItem>
            </TreeItem>
            <TreeItem nodeId="readerNode" label={props.t('shop.reader')}>
                {props.products?.filter(p => p.category === 'reader').map((prod, index) => (
                    <TreeItem nodeId={prod.id} label={props.t('products:' + prod.id + '.name')} />
                ))}
            </TreeItem>

            <TreeItem nodeId="tagNode" label={props.t('shop.tag')}>
                {props.products?.filter(p => p.category === 'tag').map((prod, index) => (
                    <TreeItem nodeId={prod.id} label={props.t('products:' + prod.id + '.name')} />
                ))}
            </TreeItem>

        </TreeView>
    );
}

const mapStateToProps = (state) => {
    return {
        products: state.products.products
    };
};

const mapDispatchToProps = (dispatch) => ({

});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(['translation', 'products'])
)(ShopTreeView);