import React from 'react';
import {
    Typography,
    Box,
    Divider,
    Button,
    Link,
    Rating
} from '@mui/material';
import { styled } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#42a5f5',
    }
});


const GeneratorResult = ({ productList, listIndex }) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const { lang } = useParams();
    function customNavigate(link) {
        return ("/" + lang + link)
    }
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6'>
                        #{listIndex + 1}
                    </Typography>
                </Box>

                {productList.map((product, index) =>
                    <Box sx={{ display: 'flex', padding: 2, flexWrap: 'wrap' }} key={listIndex + product.name + index} >
                        <img
                            //component="img"
                            //sx={{ maxHeight: 160, maxWidth: 160 }}
                            src={"/images/productImages/variations/" + product.variation.image.file}
                            alt={product.variation.image.name}
                            height={160}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 5, minWidth: '150px' }}>
                            <Typography variant='h6' component={Link} href={customNavigate('/product/' + product.productId)} target="_blank"
                                sx={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    boxShadow: "none"
                                }}>
                                {product.name} <OpenInNewIcon fontSize="small" />
                            </Typography>
                            <Typography >
                                {t('generator.width')}: {product.variation.width}
                            </Typography>
                            <Typography >
                                {t('generator.totalWidth')}: {product.variation.totalWidth}mm
                            </Typography>
                            <Typography >
                                {t('generator.height')}: {product.variation.height}mm
                            </Typography>
                            <Typography component="legend">{t('generator.style.price')}</Typography>
                            <StyledRating size="small" value={product.priceCategory} max={10} readOnly />
                            <Typography component="legend">{t('generator.style.visual')}</Typography>
                            <StyledRating size="small" value={product.visualAppearance} readOnly />
                        </Box>

                    </Box>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginTop: 2 }}>
                    <Typography variant='h6'>
                        {t('generator.summary')}
                    </Typography>
                    <Typography >
                        {t('generator.totalWidth')}: {productList.reduce((prev, next) => prev + next.variation.totalWidth, 0)}mm
                    </Typography>
                    <Typography >
                        {t('generator.installationWidth')}: {productList.reduce((prev, next) => prev + next.variation.totalWidth, 0) + 10 + (productList.length - 1) * 10}mm
                    </Typography>
                    {false &&
                        <Button size={"small"} sx={{ m: 0, p: 0, minWidth: 0, marginRight: 'auto', marginTop: 1 }}>
                            requestQuotation
                        </Button>
                    }
                </Box>

            </Box>
            < Divider />
        </>
    );
}

export default GeneratorResult;