import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const HelmetHelper = ({ page }) => {

    const { t, i18n } = useTranslation();
    const { lang } = useParams();
    const url = window.location.href;

    if (!lang) {
        console.log("No language selected")
    }

    console.log("Lang: ", lang)
    console.log("i18n: ", i18n)

    //How to get locale not language
    //<meta property="og:locale" content={i18n.language} />
    return (
        <Helmet>
            <html lang={i18n.language} />



            <title>{t(page + '.seo.title') + " - Gatech"}</title>
            <meta property="og:title" content={t(page + '.seo.title')} />
            <meta name="description" content={t(page + '.seo.description')} />
            <meta name="og:description" content={t(page + '.seo.description')} />
            <meta name="og:url" content={url} />
            <meta property="og:image" content="https://gatech.fi/images/gatech_logo_black.png" />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="188" />

            {page === 'home' ?
                <>
                    <link href={"https://gatech.fi/"} rel="alternate" hreflang={'en'} />
                    {i18n.options.fallbackLng.map(lan => {
                        if (lan != "en") {
                            return (<link href={"https://gatech.fi/" + lan} rel="alternate" hreflang={lan} key={lan} />)
                        }
                    })}
                    {lang === "en" ?
                        <link rel="canonical" href={"https://gatech.fi/"} />
                        :
                        <link rel="canonical" href={"https://gatech.fi/" + lang} />
                    }

                </>
                :
                <>
                    {i18n.options.fallbackLng.map(lan => (
                        <link href={"https://gatech.fi/" + lan + "/" + page} rel="alternate" hreflang={lan} key={lan} />
                    ))}
                    <link rel="canonical" href={"https://gatech.fi/" + lang + "/" + page} />
                </>
            }
        </Helmet>
    );
}

export default HelmetHelper;