import React, { useState, useEffect, useRef } from 'react';
import { Fade } from "react-awesome-reveal";
import FeatureTile from './partials/FeatureTile';
import CheckMobileScreen from '../../utils/CheckMobileScreen';
import { useSSR, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    Container,
    Grid,
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    TextField,
    Button,
    ButtonGroup,
    Stack,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Stepper,
    Step,
    StepLabel,
    StepButton,
    Switch,
    Slider,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import variations from '../../assets/generator/variations.json';
import { Flag, NoiseAware, PropaneTank, TryRounded } from '@mui/icons-material';
import GeneratorStep from '../elements/GeneratorStep';
import GeneratorResult from '../elements/GeneratorResult';
import SelectComponent from '../elements/SelectComponent';

const steps = ['target', 'room', 'passages', 'security', 'style'];
const orderResults = ['priceAsc', 'favourite', 'priceDesc'];


const listSorting = (listA, listB) => {
    const lists = [listA, listB];
    const points = [1000, 1000];

    lists.map((list, listIndex) => {
        list.map(product => {
            points[listIndex] -= product.variation.priceCategory * 2;
            points[listIndex] += product.visualAppearance;
            points[listIndex] += product.variation.default * 5;
        })
        points[listIndex] -= (list.length * 100);
        points[listIndex] += (list.every((x) => x.name === list[0].name) * 10);
    })

    return points[0] >= points[1] ? -1 : 1;
}

const priceSortingAsc = (listA, listB) => {
    const lists = [listA, listB];
    const points = [1000, 1000];
    lists.map((list, listIndex) => {
        list.map(product => {
            points[listIndex] -= product.variation.priceCategory * 2;
        })
    })

    return points[0] >= points[1] ? -1 : 1;
}
const priceSortingDesc = (listA, listB) => {
    const lists = [listA, listB];
    const points = [1000, 1000];
    lists.map((list, listIndex) => {
        list.map(product => {
            points[listIndex] -= product.variation.priceCategory * 2;
        })
    })

    return points[0] <= points[1] ? -1 : 1;
}

const flattenList = (list) => {
    var allProducts = [];
    list.products.map((product, prodIndex) => {
        product.variations.map((variation, varIndex) => {
            allProducts = [...allProducts, { ...product, variation, id: prodIndex + ":" + varIndex }]
        })
    })
    return allProducts;
}


const SelectTargetTab = (props) => {
    const { t } = props
    return (
        <GeneratorStep
            description={t('generator.target.description')}
        >
            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={props.selectedTarget}
                onChange={props.selectedTargetChange}
            >
                <FormControlLabel value="office" control={<Radio />} label={t('generator.target.office')} />
                <FormControlLabel value="gym" control={<Radio />} label={t('generator.target.gym')} />
                <FormControlLabel value="outdoor" control={<Radio />} label={t('generator.target.outdoor')} />
                <FormControlLabel value="other" control={<Radio />} label={t('generator.target.other')} />
            </RadioGroup>
        </GeneratorStep>
    )
}

const SelectPassagesTab = (props) => {

    const { noPassages, noEmPassages, noInvaPassages, setNoEmPassages, setNoPassages, setNoInvaPassages, t } = props;
    return (
        <GeneratorStep
            description={t('generator.passages.description')}
        >
            <TextField
                id="standard-number"
                label={t('generator.passages.normal')}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                value={noPassages}
                onChange={(event) => {
                    setNoPassages(event.target.value ? parseInt(event.target.value) : '');
                }}
                variant="standard"
            />
            <TextField
                id="standard-number"
                label={t('generator.passages.inva')}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                value={noInvaPassages}
                onChange={(event) => {
                    setNoInvaPassages(event.target.value ? parseInt(event.target.value) : '');
                }}
                variant="standard"
            />
            <TextField
                id="standard-number"
                label={t('generator.passages.emergency')}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                value={noEmPassages}
                onChange={(event) => {
                    setNoEmPassages(event.target.value ? parseInt(event.target.value) : '');
                }}
                variant="standard"
            />
        </GeneratorStep>
    )
}

const SelectSecurityTab = (props) => {
    const { jumpOver, setJumpOver, tailGatingInva, setTailGatingInva, tailGating, setTailGating, t } = props;
    return (
        <GeneratorStep
            description={t('generator.security.description')}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <FormControlLabel
                    checked={jumpOver}
                    control={<Switch color="primary" />}
                    onChange={() => setJumpOver(!jumpOver)}
                    label={t('generator.security.jumpOver')}
                    labelPlacement="end"
                />
                <FormControlLabel
                    checked={tailGating}
                    control={<Switch color="primary" />}
                    onChange={() => setTailGating(!tailGating)}
                    label={t('generator.security.tailgating')}
                    labelPlacement="end"
                />
                <FormControlLabel
                    checked={tailGatingInva}
                    control={<Switch color="primary" />}
                    onChange={() => setTailGatingInva(!tailGatingInva)}
                    label={t('generator.security.tailgatingInva')}
                    labelPlacement="end"
                />
            </Box>
        </GeneratorStep >
    )
}

const SelectStyleTab = (props) => {
    const { priceCategory, setPriceCategory, visualApp, setVisualApp, t } = props;
    return (
        <GeneratorStep
            description={t('generator.style.description')}
        >

            <FormControlLabel
                sx={{ width: 250 }}
                control={<Slider
                    value={visualApp}
                    onChange={(event) => setVisualApp(event.target.value)}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={5}
                    track="inverted"
                />}
                label={t('generator.style.visual')}
                labelPlacement="top"
            />
        </GeneratorStep >
    )
}

const SelectRoomTab = (props) => {

    const { maxWidth, setMaxWidth, maxLength, setMaxLength, setOutdoor, outdoor, t } = props;
    return (
        <GeneratorStep
            description={t('generator.room.description')}
        >
            <TextField
                id="standard-number"
                label={t('generator.room.width')}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                value={maxWidth}
                onChange={(event) => {
                    setMaxWidth(event.target.value ? parseInt(event.target.value) : '');
                }}
                variant="standard"
                InputProps={{
                    startAdornment: <InputAdornment position="start">mm</InputAdornment>,
                }}
            />
            <TextField
                id="standard-number"
                label={t('generator.room.length')}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                value={maxLength}
                onChange={(event) => {
                    setMaxLength(event.target.value ? parseInt(event.target.value) : '');
                }}
                variant="standard"
                InputProps={{
                    startAdornment: <InputAdornment position="start">mm</InputAdornment>,
                }}
            />
            <FormControlLabel
                checked={outdoor}
                control={<Switch color="primary" />}
                onChange={() => setOutdoor(!outdoor)}
                label={t('generator.room.outdoor')}
                labelPlacement="top"
            />

        </GeneratorStep>
    )
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ShowDialogForEntrances = ({ open, setOpen, warning, t }) => {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{warning ? t('generator.alertDialog.warning.title') : t('generator.alertDialog.error.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {warning ? t('generator.alertDialog.warning.text') : t('generator.alertDialog.error.text')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}


const Generator = (props) => {
    const lookupListRef = useRef([])
    const availableProductsRef = useRef([])
    const [availableProducts, setAvailableProducts] = useState([]);
    const [maxWidth, setMaxWidth] = useState(0);
    const [maxLength, setMaxLength] = useState(0);
    const [noPassages, setNoPassages] = useState(1);
    const [noInvaPassages, setNoInvaPassages] = useState(0);
    const [noEmPassages, setNoEmPassages] = useState(0);
    const infoShownRef = useRef(false)
    const [orderValue, setOrderValue] = useState("favourite")

    const [openDialog, setOpenDialog] = useState(false)
    const [dialogWarn, setDialogWarn] = useState(false)

    const [jumpOver, setJumpOver] = useState(false);
    const [tailGatingInva, setTailGatingInva] = useState(false);
    const [visualApp, setVisualApp] = useState(0);
    const [outdoor, setOutdoor] = useState(true);
    const [tailGating, setTailGating] = useState(false);

    const [selectedTarget, setSelectedTarget] = useState('office')
    const [activeStep, setActiveStep] = React.useState(0);

    const passageProps = { noPassages, setNoPassages, noInvaPassages, setNoInvaPassages, noEmPassages, setNoEmPassages, t: props.t };
    const roomProps = { maxWidth, setMaxWidth, maxLength, setMaxLength, setOutdoor, outdoor, t: props.t };
    const securityProps = { jumpOver, setJumpOver, tailGatingInva, setTailGatingInva, tailGating, setTailGating, t: props.t }
    const styleProps = { visualApp, setVisualApp, t: props.t }

    useEffect(() => {
        selectedTargetChange({ target: { value: 'office' } })
    }, []);

    useEffect(() => {

        if (noPassages + noInvaPassages + noEmPassages > 2 && infoShownRef.current === false) {
            setDialogWarn(true);
            setOpenDialog(true)
            infoShownRef.current = true
        }

        if (noPassages + noInvaPassages + noEmPassages > 5) {
            setDialogWarn(false);
            setOpenDialog(true)
            setNoEmPassages(0);
            setNoPassages(0);
            setNoInvaPassages(0)
            return;
        }

        const getResults = async () => {
            const result = await generateResultsAsync();
        }
        getResults();
    }, [maxWidth, noPassages, noInvaPassages, noEmPassages, jumpOver, tailGatingInva, visualApp, outdoor, tailGating]);

    function generateResultsAsync() {
        return new Promise((resolve) => {
            availableProductsRef.current = [];
            lookupListRef.current = []
            generateResults([], 0, 0, 0, 0, 0, 0)
            sortResults(orderValue);
            console.log("Results ready")
            resolve(true)
        });
    }

    const orderValueChanged = (event) => {
        setOrderValue(event.target.value)
        sortResults(event.target.value);
    }

    const sortResults = (value) => {
        if (value === 'favourite') {
            setAvailableProducts(availableProductsRef.current.sort((a, b) => listSorting(a, b)))
        }

        if (value === 'priceAsc') {
            setAvailableProducts(availableProductsRef.current.sort((a, b) => priceSortingAsc(a, b)))
        }
        if (value === 'priceDesc') {
            setAvailableProducts(availableProductsRef.current.sort((a, b) => priceSortingDesc(a, b)))
        }
    }

    const generateResults = (usedProducts, currentIndex, usedWidth, usedPassages, usedInvaPassages, usedEmPassages, usedSpeedGates,
        productTypes = [], productModels = [], blockedTypes = [], blockedModels = []) => {

        if (usedPassages >= noPassages &&
            usedInvaPassages >= noInvaPassages &&
            usedEmPassages >= noEmPassages
        ) {
            availableProductsRef.current = [...availableProductsRef.current, usedProducts]
            return true
        }
        flattenList(variations).slice(currentIndex).map((product, index) => {
            if ((usedWidth + product.variation.totalWidth <= maxWidth || maxWidth === 0 || maxWidth === '') &&

                usedPassages + product.variation.noPassages <= noPassages &&
                usedInvaPassages + product.variation.noInvaPassages <= noInvaPassages &&
                usedEmPassages + product.variation.noEmergencyPassages <= noEmPassages &&

                (product.variation.totalLength <= maxLength || maxLength === 0 || maxLength === '') &&
                (jumpOver == true ? product.variation.overJumpSecure : true) &&
                product.variation.outdoor >= outdoor &&
                product.visualAppearance >= visualApp &&
                ((tailGating == true ? product.tailGatingBlocked : true) ||
                    (tailGatingInva == false && (product.type === 'swingGate' || product.type === 'fullHeightSwingGate') && noPassages > 0)) &&
                ((product.variation.speedGate ? 1 : 0) + usedSpeedGates < 2) &&
                !blockedTypes.includes(product.type) &&
                !product.dontCombineWithType.some(r => productTypes.includes(r)) &&
                !blockedModels.includes(product.productId) &&
                !product.dontCombineWithModel.some(r => productModels.includes(r))
            ) {
                generateResults(
                    [...usedProducts, product],
                    index,
                    usedWidth + product.variation.totalWidth,
                    usedPassages + product.variation.noPassages,
                    usedInvaPassages + product.variation.noInvaPassages,
                    usedEmPassages + product.variation.noEmergencyPassages,
                    usedSpeedGates + product.variation.speedGate ? 1 : 0,
                    [...productTypes, product.type],
                    [...productModels, product.productId],
                    [...blockedTypes, ...product.dontCombineWithType],
                    [...blockedModels, ...product.dontCombineWithModel]
                )
                //console.log("availablevariation ", product.variation)
            }
        })

        return false;
    }

    const selectedTargetChange = (event) => {
        const target = event.target.value
        console.log("setSelectedTarget ", target)
        setSelectedTarget(target)

        if (target === 'office') {
            setJumpOver(false);
            setOutdoor(false);
            setVisualApp(3);
            setTailGating(false);
            setTailGatingInva(false);
        }

        if (target === 'gym') {
            setJumpOver(false);
            setOutdoor(false);
            setVisualApp(1);
            setTailGating(true);
            setTailGatingInva(false);
        }
        if (target === 'outdoor') {
            setJumpOver(false);
            setOutdoor(true);
            setVisualApp(1);
            setTailGating(true);
            setTailGatingInva(false);
        }
        if (target === 'other') {
            setJumpOver(false);
            setOutdoor(false);
            setVisualApp(1);
            setTailGating(false);
            setTailGatingInva(false);
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Container maxWidth='lg' >
            <ShowDialogForEntrances
                open={openDialog}
                warning={dialogWarn}
                setOpen={setOpenDialog}
                t={props.t}
            />
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} nonLinear orientation={CheckMobileScreen() ? 'vertical' : 'horizontal'}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton
                                    {...labelProps}
                                    onClick={handleStep(index)}
                                >
                                    {props.t('generator.' + label + '.name')}
                                </StepButton >
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === 0 &&
                    <SelectTargetTab
                        selectedTarget={selectedTarget}
                        selectedTargetChange={selectedTargetChange}
                        t={props.t}
                    />
                }

                {activeStep === 1 &&
                    <SelectRoomTab
                        {...roomProps}
                    />
                }
                {activeStep === 2 &&
                    <SelectPassagesTab
                        {...passageProps}
                    />
                }
                {activeStep === 3 &&
                    <SelectSecurityTab
                        {...securityProps}
                    />
                }
                {activeStep === 4 &&
                    <SelectStyleTab
                        {...styleProps}
                    />
                }
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center' }}>
                            <Button
                                //color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                {props.t('generator.modify')}
                            </Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-evenly' }}>
                            <Button
                                //color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                {props.t('generator.back')}
                            </Button>
                            <Box />

                            <Button onClick={handleNext}>
                                {activeStep != steps.length - 1 ?
                                    props.t('generator.next')
                                    :
                                    props.t('generator.finish')
                                }
                            </Button>

                        </Box>
                    </React.Fragment>
                )}
            </Box>
            < Divider />
            <Box
                sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
                <Typography variant='h6'>
                    {props.t('generator.alternativesFound') + ' ' + availableProducts.length}
                </Typography>
                <SelectComponent
                    value={orderValue}
                    handleChange={orderValueChanged}
                    items={orderResults}
                    label={props.t('generator.sortOrder')}
                    langRoot={'generator'}
                />
            </Box>

            {
                availableProducts.slice(0, 10).map((productList, listIndex) =>
                    <GeneratorResult key={"divider" + listIndex}
                        productList={productList}
                        listIndex={listIndex}
                    />
                )
            }
        </Container >
    )
}
const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Generator);