import React, { useState, useEffect } from 'react';

import Hero from '../components/sections/Hero';
import Appbar from '../components/elements/Appbar';
import GeneratorSection from '../components/sections/Generator';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HelmetHelper from '../utils/HelmetHelper';

const Generator = (props) => {
    const [searchTerm, setSearchTerm] = useState('');


    return (
        <>
            <HelmetHelper
                page={'generator'}
            />
            <Appbar
                hideSearch
                onSearchChange={(text) => setSearchTerm(text)}
            />
            <Hero small />
            <GeneratorSection />
        </>
    )
}

export default Generator;