import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import FeatureTile from './partials/FeatureTile';
import CheckMobileScreen from '../../utils/CheckMobileScreen';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    Container,
    Grid,
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    TextField,
    Button,
    ButtonGroup,
    Stack
} from '@mui/material';
import LangHref from '../../utils/LangHref';

const GateFeatures = (props) => {


    const ScrollTo = (toId) => {
        console.log("Clicked")
        const anchor = document.getElementById(toId)
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth" })
        } else {
            console.log("not found")
        }
    }

    return (
        <Container maxWidth='lg' sx={{ marginBottom: 2 }}>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography variant='h6' textAlign={'center'} maxWidth={'md'}>
                    {props.t('gates.description')}
                </Typography>
            </Box>
            <Box style={{ "margin": 20 }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                <ButtonGroup variant="text" orientation={CheckMobileScreen() ? 'vertical' : 'horizontal'}>
                    <Button onClick={() => ScrollTo('speedTile')}>{props.t('gates.speedTile.title')}</Button>
                    <Button onClick={() => ScrollTo('waistTile')}>{props.t('gates.waistTile.title')}</Button>
                    <Button onClick={() => ScrollTo('wingTile')}>{props.t('gates.wingTile.title')}</Button>
                    <Button onClick={() => ScrollTo('highTile')}>{props.t('gates.highTile.title')}</Button>
                </ButtonGroup>
            </Box>

            <FeatureTile
                id='speedTile'
                title={props.t('gates.speedTile.title')}
                text={props.t('gates.speedTile.text')}
                link={props.t('header.shop')}
                linkRef={LangHref('/shop')}
                link2={props.t('header.generator')}
                linkRef2={LangHref('/generator')}
                image={'/images/productImages/' + props.t('gates.speedTile.image.file')}
                reverse
            />
            <FeatureTile
                id='waistTile'
                title={props.t('gates.waistTile.title')}
                text={props.t('gates.waistTile.text')}
                link={props.t('header.shop')}
                linkRef={LangHref('/shop')}
                link2={props.t('header.generator')}
                linkRef2={LangHref('/generator')}
                image={'/images/productImages/' + props.t('gates.waistTile.image.file')}

            />
            <FeatureTile
                id='wingTile'
                title={props.t('gates.wingTile.title')}
                text={props.t('gates.wingTile.text')}
                link={props.t('header.shop')}
                linkRef={LangHref('/shop')}
                link2={props.t('header.generator')}
                linkRef2={LangHref('/generator')}
                image={'/images/productImages/' + props.t('gates.wingTile.image.file')}
                reverse
            />
            <FeatureTile
                id='highTile'
                title={props.t('gates.highTile.title')}
                text={props.t('gates.highTile.text')}
                link={props.t('header.shop')}
                linkRef={LangHref('/shop')}
                link2={props.t('header.generator')}
                linkRef2={LangHref('/generator')}
                image={'/images/productImages/' + props.t('gates.highTile.image.file')}
            />

            <Box display={'flex'} justifyContent={'center'}>
                <Typography variant='h6' textAlign={'center'} maxWidth={'md'} color="text.secondary" >
                    {props.t('gates.bottomDescription')}
                </Typography>
            </Box>
        </Container >
    )
}
const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(GateFeatures);